import styled from "styled-components";
import Skeleton from "../../components/ui/Skeleton";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import { useCache } from "../../caching/caches";
import { breakPoints } from "../../utils/config";
import MusicEntryCard from "../../components/cards/MusicEntryCard";
import Tag from "../../sharedUI/Tag";
import { useMemo, useState } from "react";

const StyledMusicPage = styled.div`
  padding-bottom: 20rem;
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  ${breakPoints.mobile} {
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
`;

const MusicPage = () => {
  const { loading, state: entries } = useCache("music");

  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (tag) => {
    const updated = [...selectedTags];
    const index = selectedTags.indexOf(tag);
    if (index === -1) updated.push(tag);
    else updated.splice(index, 1);
    setSelectedTags(updated);
  };

  const allTags = useMemo(() => {
    const tags = entries.flatMap((entry) => entry.tags);
    return [...new Set(tags)];
  }, [entries]);

  const filteredEntries = useMemo(() => {
    return entries.filter((entry) => {
      return selectedTags.every((tag) => entry.tags.includes(tag));
    });
  }, [entries, selectedTags]);

  return (
    <StyledMusicPage className="content">
      <h1 className="mtop2">Musik</h1>
      <div className="tags-container">
        {allTags.map((tag, i) => (
          <Tag
            key={`tag_${tag}_${i}`}
            tagName={tag}
            selected={selectedTags.includes(tag)}
            select={() => toggleTag(tag)}
          />
        ))}
      </div>
      <div className="cards-container">
        {loading ? (
          <Skeleton
            identifier="music"
            quantity={5}
            Component={CourseCardLoader}
          />
        ) : filteredEntries.length ? (
          filteredEntries.map((entry) => (
            <MusicEntryCard key={entry._id} entry={entry} />
          ))
        ) : (
          <p>Inga musikfiler hittades</p>
        )}
      </div>
    </StyledMusicPage>
  );
};

export default MusicPage;
